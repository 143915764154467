import CatalogImage from 'components/MediaServerImage/CatalogImage';
import Featuring from 'shared/ui/components/Featuring';
import Head from './Head';
import Hero from 'views/Profile/Hero';
import NavLink from 'components/NavLink';
import P from 'primitives/Typography/BodyCopy/P';
import PageBody, { ViewName } from 'views/PageBody';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonContainerPrimitive from 'components/Artist/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import RenameFavorites from 'shared/ui/components/RenameFavorites';
import Section from 'components/Section';
import SectionStyles from 'primitives/Section';
import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';
import transport from 'api/transport';
import useMFRInfo from './useMFRInfo';
import useMount from 'hooks/useMount';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Artist } from 'state/Artists/types';
import { artistReceived } from 'state/Artists/actions';
import { ConnectedModals } from 'state/UI/constants';
import { getAmpUrl, getSiteUrl } from 'state/Config/selectors';
import {
  getCurrentFavoritesId,
  getFavoriteArtists,
  getHasMFR,
  getName,
  getSeedId,
  getSlug,
} from 'state/Favorites/selectors';
import { getIsAnonymous, getProfileId } from 'state/Session/selectors';
import { getLocation, getResourceId, getSlugId } from 'state/Routing/selectors';
import { getModal } from 'state/UI/selectors';
import { getMyFavoritesStation } from 'state/Favorites/services';
import { getPreferences } from 'state/Profile/selectors';
import { mapFavorites } from 'state/Favorites/helpers';
import { navigate } from 'state/Routing/actions';
import { openModal, openSignupModal } from 'state/UI/actions';
import { setHasHero } from 'state/Hero/actions';
import { setHasMFR, setMyFavoritesRadio } from 'state/Favorites/actions';
import { slugify } from 'utils/string';
import { STATION_TYPE } from 'constants/stationTypes';
import { TILE_RES } from 'components/MediaServerImage';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { WIDGET_DIMENSIONS } from 'constants/widgetDimensions';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

function Favorites() {
  const dispatch = useDispatch();
  const translate = useTranslate();

  const [artists, setArtists] = useState<Array<Artist>>(
    useSelector(getFavoriteArtists),
  );
  const [seedId, setSeedId] = useState(useSelector(getSeedId));
  const [slug, setSlug] = useState(useSelector(getSlug));
  const [name, setName] = useState(useSelector(getName));
  const [favoritesStationData, setFavoritesStationData] =
    useState<Record<string, any>>();

  const { 'favorites.named': favoritesNamed } =
    useSelector(getPreferences) ?? {};
  const hasMFR = useSelector(getHasMFR);
  const isAnonymous = useSelector(getIsAnonymous);
  const { id: modalId } = useSelector(getModal) ?? {};
  const { pathname } = useSelector(getLocation) ?? {};
  const profileId = useSelector(getProfileId);
  const siteUrl = useSelector(getSiteUrl);
  const title = useSelector(getName);
  const urlSlugId = useSelector(getResourceId) as string;

  const ampUrl = useSelector(getAmpUrl);
  const id = useSelector(getSlugId);
  const currentFavoritesId = useSelector(getCurrentFavoritesId);

  const favoritesId: number = id ?? currentFavoritesId;

  useMount(() => {
    const fetchMyFavoritesStation = async () => {
      await transport(
        getMyFavoritesStation(favoritesId, ampUrl, { bustCache: true }),
      ).then(value => {
        return setFavoritesStationData(value.data);
      });
    };
    fetchMyFavoritesStation();

    if (!isAnonymous && hasMFR === false) {
      // this will trigger a new check for MFR in case a new user has developed a listening history
      dispatch(setHasMFR(undefined));
    }
    // if anonymous and visiting /favorites directly (no slug id in the url)
    if (isAnonymous && !urlSlugId) {
      dispatch(openSignupModal({ context: 'reg-gate' }));
    }
  });

  useEffect(() => {
    if (favoritesStationData) {
      const {
        artists: mappedArtists,
        description,
        imagePath,
        link,
        name: mappedName,
        seedId: mappedSeedId,
        slug: mappedSlug,
      }: {
        artists: Array<Artist>;
        description: string;
        imagePath: string;
        link: string;
        name: string;
        seedId: number;
        slug: string;
      } = mapFavorites(favoritesStationData, favoritesId);

      setSeedId(mappedSeedId);
      setArtists(mappedArtists);
      setSlug(mappedSlug);
      setName(mappedName);

      dispatch(artistReceived(mappedArtists));
      dispatch(
        setMyFavoritesRadio({
          artists: mappedArtists,
          description,
          imagePath,
          link,
          name: mappedName,
          seedId: mappedSeedId,
          slug: mappedSlug,
        }),
      );
      dispatch(setHasHero(true));
    }
  }, [favoritesStationData, setArtists, dispatch, favoritesId, ampUrl]);

  const {
    correctSlug,
    getStationInfo,
    isActiveUser,
    needStationInfo,
    needsToNameStation,
    showHowToPage,
  } = useMFRInfo({
    favoritesNamed,
    hasMFR,
    isAnonymous,
    modalId,
    profileId,
    seedId,
    slug,
    urlSlugId,
  });

  useEffect(() => {
    if (showHowToPage) {
      window.location =
        `${siteUrl}/content/how-to-create-the-most-awesome-radio-station-ever-with-my-favorites-radio/?cmp=ihr-favorites-noteligible` as unknown as Location;
    }
  }, [showHowToPage, siteUrl]);

  useEffect(() => {
    if (needsToNameStation) {
      dispatch(
        openModal({
          context: null,
          id: ConnectedModals.NewFavorites,
        }),
      );
    }
    // pathname: open modal on each location change (ie, ClientRouter
    // slug-corrections) as location changes auto-close the modal
  }, [dispatch, needsToNameStation, pathname]);

  useEffect(() => {
    if (needStationInfo) {
      dispatch(getStationInfo?.());
    }
  }, [dispatch, getStationInfo, needStationInfo]);

  useEffect(() => {
    if (correctSlug) {
      dispatch(navigate({ path: `/favorites/${correctSlug}/`, replace: true }));
    }
  }, [correctSlug, dispatch]);

  const heading = isActiveUser ? <RenameFavorites stationName={title} /> : null;

  const social = {
    dimensions: WIDGET_DIMENSIONS.FAVORITES,
    hideDescription: true,
    seedId,
    seedType: STATION_TYPE.FAVORITES,
    stationName: title,
    url: pathname as string,
  };

  return seedId ?
      <>
        <Head name={name} seedId={seedId} />
        <Hero isMFR={isActiveUser} />
        <PageBody
          dataTest={ViewName.Favorites}
          hasHero
          heading={heading}
          social={social}
          title={title}
        >
          <SectionStyles type="top">
            <P color={colors => colors.gray.medium}>
              {isActiveUser || !profileId ?
                translate(
                  'Welcome to Your Favorites Radio, a personalized station containing your favorite songs and artists. The more you thumb up and save stations on iHeart, the better your listening experience will be.',
                )
              : translate(
                  'Listen to {title}. Thumb up songs to add them to your own Your Favorites Radio station. Find it under My Stations.',
                  { title },
                )
              }
            </P>
          </SectionStyles>
          <Section header={translate('Featured Artists On This Station')}>
            <Tiles tilesInRow={3}>
              {artists?.map(({ name: tileName, artistId }) => {
                const tileUrl = `/artist/${slugify(tileName)}-${artistId}/`;

                return (
                  <Tile
                    isRoundImage
                    key={`myFavorites|${artistId}`}
                    subTitle={
                      <Featuring
                        artistId={artistId}
                        key={`featuring|${artistId}`}
                      />
                    }
                    tilesInRow={3}
                    title={tileName}
                    url={tileUrl}
                  >
                    <NavLink
                      css={{ display: 'block', position: 'relative' }}
                      to={tileUrl}
                    >
                      <PlayButtonContainerPrimitive>
                        <PlayButton
                          className="play"
                          deferPlay
                          playedFrom={PLAYED_FROM.PROF_FAVORITES_MAIN_ARTISTS}
                          seedId={artistId}
                          stationId={artistId}
                          stationType={STATION_TYPE.ARTIST}
                        />
                      </PlayButtonContainerPrimitive>
                      <CatalogImage
                        alt={tileName}
                        aspectRatio={1}
                        height={TILE_RES}
                        id={artistId}
                        type={STATION_TYPE.ARTIST}
                        width={TILE_RES}
                      />
                    </NavLink>
                  </Tile>
                );
              })}
            </Tiles>
          </Section>
        </PageBody>
      </>
    : null;
}

export default Favorites;
