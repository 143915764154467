import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import {
  buildCatalogMetaUrl,
  buildImageUrl,
} from 'utils/mediaServerImageMaker/mediaServerImageUrl';
import { Helmet } from 'react-helmet';
import { link as playerLink, meta as playerMeta } from 'heads/player';
import { translateKeywords } from 'utils/metaUtils';
import {
  WIDGET_DIMENSIONS,
  WidgetDimensions,
} from 'constants/widgetDimensions';
import type { Artist } from 'state/Artists/types';

type Props = {
  favoriteArtists: Array<Artist>;
  mediaServerUrl: string;
  name: string;
  seedId: string | number;
  siteUrl: string;
};

function Head({
  favoriteArtists,
  mediaServerUrl,
  seedId,
  siteUrl,
  name = '',
}: Props) {
  const translate = useTranslate();

  const description = favoriteArtists
    .slice(0, 3)
    .map(artist => artist?.name ?? '')
    .join(', ');

  const title = translate("{name}'s Favorites Radio", { name });
  const keywords = translateKeywords(
    translate,
    'favorite songs, favorite music, personal radio station, personalized radio station, custom radio station',
  );
  const pagePath = `favorites/${name}-favorites-radio-${seedId}`;

  const ogImage = buildImageUrl(
    buildCatalogMetaUrl(mediaServerUrl, {
      id: seedId,
      resourceType: 'favorites',
    }),
  )();

  const { height, fixedWidth: width }: WidgetDimensions =
    WIDGET_DIMENSIONS.FAVORITES ?? {};

  return (
    <>
      <GenericHead
        description={description}
        image={ogImage}
        metaDescription={description}
        noAltLink
        ogType="music.playlist"
        title={title}
      />
      <Helmet
        link={playerLink({ pagePath, siteUrl, title })}
        meta={[
          { content: keywords, name: 'keywords' },
          ...playerMeta({ pagePath, siteUrl, height, width }),
        ]}
      />
    </>
  );
}

export default Head;
