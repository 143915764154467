import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const EditButton = styled('button')(({ theme }) => ({
  backgroundColor: theme.colors.transparent.primary,
  border: 'none',
  minWidth: 'unset',
  padding: 0,
  paddingLeft: '0.8rem',
  width: '3rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['640'])]: {
    width: '3rem',
  },
}));

export default EditButton;
