import styled from '@emotion/styled';

const CurrentName = styled('h1')({
  display: 'inline',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
});

export default CurrentName;
