import localStorage from 'utils/localStorage';
import RenameMFR from 'components/FormFields/RenameMFR/MFREditMode';
import transport from 'api/transport';
import { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash-es';
import { getAmpUrl } from 'state/Config/selectors';
import {
  getFavoritesBySeedId,
  renameFavoritesRadio,
} from 'state/Favorites/services';
import { getProfileId, getSessionId } from 'state/Session/selectors';
import { setMyFavoritesRadioName as setMyFavoritesRadioNameAction } from 'state/Favorites/actions';
import { setTitle } from 'state/Hero/actions';

class RenameFavorites extends Component {
  static propTypes = {
    stationId: PropTypes.number,
    stationName: PropTypes.string,
  };

  getInitialState() {
    let currentStationName = this.props.stationName || 'My';
    let originalStationName = '';
    if (currentStationName.indexOf(' Favorites Radio') > -1) {
      originalStationName = currentStationName.split(' Favorites Radio')[0]; // eslint-disable-line prefer-destructuring
      currentStationName = currentStationName.split(' Favorites Radio')[0]; // eslint-disable-line prefer-destructuring
    }

    return {
      origionalName: originalStationName,
      stationId: this.props.stationId,
      stationName: currentStationName,
      validated: false,
    };
  }

  state = this.getInitialState();

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    let newStation = newProps.stationName;
    if (newStation.indexOf(' Favorites Radio')) {
      newStation = newStation.split(' Favorites Radio')[0]; // eslint-disable-line prefer-destructuring
    }
    this.setState({
      stationName: newStation,
    });
  }

  onSubmit = newName => {
    const {
      ampUrl,
      profileId,
      sessionId,
      setHeroTitle,
      setMyFavoritesRadioName,
    } = this.props;
    const newStationName = newName.trim(); // just in case the user left a trailing space
    const stationDisplayName = `${newName} Favorites Radio`;
    transport(
      getFavoritesBySeedId({ ampUrl, profileId, seedId: profileId, sessionId }),
    ).then(({ data }) => {
      let newStationId = this.state.stationId;

      const responseId = get(data, ['value', 'id']);

      if (responseId && __CLIENT__) {
        newStationId = responseId;
        localStorage.setItem('ihr-favorites-id', responseId);
      } else {
        newStationId = localStorage.getItem('ihr-favorites-id');
      }

      if (!newStationName) {
        return false;
      }

      transport(
        renameFavoritesRadio({
          ampUrl,
          name: newStationName,
          profileId,
          sessionId,
          stationId: newStationId,
        }),
      ).then(
        ({ data: result }) => {
          if (result.success && __CLIENT__) {
            setHeroTitle(stationDisplayName);

            // because the only sure fire way for userHistory to get the correct information is to REFETCH EVERYTHING
            // since we can change the station in the player this primarily ensures that the entry in My Stations stays
            // up to date and prevents localStorage from holding onto the wrong value.
            setMyFavoritesRadioName(stationDisplayName);

            localStorage.setItem('ihr-favorites-name', newStationName);

            return true;
          }
          return undefined;
        },
        () => {},
      );

      return undefined;
    });

    return false;
  };

  render() {
    return (
      <div>
        <RenameMFR
          canEdit
          currentName={this.state.stationName}
          onSave={this.onSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  ampUrl: getAmpUrl,
  profileId: getProfileId,
  sessionId: getSessionId,
});
const mapDispatchToProps = {
  setHeroTitle: setTitle,
  setMyFavoritesRadioName: setMyFavoritesRadioNameAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RenameFavorites);
