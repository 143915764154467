import Head from './Head';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFavoriteArtists } from 'state/Favorites/selectors';
import { getMediaServerUrl, getSiteUrl } from 'state/Config/selectors';
import type { Artist } from 'state/Artists/types';
import type { State } from 'state/types';

export default connect(
  createStructuredSelector<
    State,
    {
      favoriteArtists: Array<Artist>;
      mediaServerUrl: string;
      siteUrl: string;
    }
  >({
    favoriteArtists: getFavoriteArtists,
    mediaServerUrl: getMediaServerUrl,
    siteUrl: getSiteUrl,
  }),
)(Head);
