import colors from 'styles/colors';
import DescriptionWrapper from 'primitives/Hero/DescriptionWrapper';
import HeroBackground from 'components/Hero/HeroBackground';
import HeroContent from 'primitives/Hero/HeroContent';
import HeroMidSection from 'primitives/Hero/HeroMidSection';
import HeroOverlay from 'components/Hero/HeroOverlay/HeroOverlay';
import HeroPlayButton from 'components/Player/HeroPlayButton';
import HeroPlaySection from 'primitives/Hero/HeroPlaySection';
import HeroRoot from 'primitives/Hero/HeroRoot';
import HeroThumbnail from 'primitives/Hero/HeroThumbnail';
import HeroTitle from 'primitives/Hero/HeroTitle';
import MediaServerImage from 'components/MediaServerImage';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import ProfilePic from 'components/ProfilePic/ProfilePic';
import ShouldShow from 'components/ShouldShow';
import ThumbnailWrapper from 'primitives/Hero/ThumbnailWrapper';
import Title from 'components/Hero/HeroTitle/HeroTitle';
import { blur, gravity } from 'utils/mediaServerImageMaker/opsString';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { PureComponent } from 'react';
import { STATION_TYPE } from 'constants/stationTypes';
import { Thumbnail } from 'components/Hero/helpers';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

type Props = {
  customRadioEnabled: boolean;
  displayName: string;
  hasMFR: boolean;
  image: string;
  isMFR?: boolean;
  mediaServerUrl: string;
  name: string;
  seedId: string;
  title: string;
  translate: IGetTranslateFunctionResponse;
  userId?: number;
};

const ThumbnailDiv = HeroThumbnail.withComponent('div');

export default class ProfileHero extends PureComponent<Props> {
  render() {
    const {
      customRadioEnabled,
      displayName,
      image,
      hasMFR,
      isMFR,
      mediaServerUrl,
      name,
      title,
      translate,
      userId,
    } = this.props;

    let { seedId } = this.props;
    if (!seedId && isMFR) seedId = (userId as number)?.toString();

    const primaryBackground =
      image && customRadioEnabled ?
        <MediaServerImage
          alt="Hero Image"
          background
          ops={[gravity('center'), blur(40)]}
          src={image}
        />
      : null;

    const favoritesTitle =
      title && title.indexOf('Favorites Radio') === -1 ?
        `${title} Favorites Radio`
      : title;
    const genericTitle = `${displayName}'s Profile`;

    return (
      <HeroRoot data-test="hero-container">
        <HeroBackground
          backgroundColor={
            customRadioEnabled ? colors.red.primary : colors.black.secondary
          }
          noMask
          primaryBackground={primaryBackground}
        />
        <HeroOverlay>
          <HeroMidSection>
            <ThumbnailWrapper>
              {customRadioEnabled ?
                <Thumbnail
                  alt={name}
                  mediaServerUrl={mediaServerUrl}
                  seedId={userId || seedId}
                  seedType={STATION_TYPE.FAVORITES}
                />
              : <ThumbnailDiv alt="Profile Picture">
                  <ProfilePic size={195} />
                </ThumbnailDiv>
              }
            </ThumbnailWrapper>
            <HeroContent>
              <HeroTitle>
                <Title
                  clamp
                  text={
                    customRadioEnabled || !isMFR ? favoritesTitle : genericTitle
                  }
                />
              </HeroTitle>
              <ShouldShow shouldShow={customRadioEnabled}>
                <HeroPlaySection>
                  {!isMFR || hasMFR ?
                    <PlayButton
                      ButtonComponent={HeroPlayButton}
                      playedFrom={PLAYED_FROM.PROF_FAVORITES_PLAY}
                      seedId={seedId}
                      stationId={seedId}
                      stationType={STATION_TYPE.FAVORITES}
                    />
                  : null}
                  <DescriptionWrapper>
                    {translate('All your favorite songs and artists.')}
                  </DescriptionWrapper>
                </HeroPlaySection>
              </ShouldShow>
            </HeroContent>
          </HeroMidSection>
        </HeroOverlay>
      </HeroRoot>
    );
  }
}
