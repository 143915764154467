import ProfileHero from './ProfileHero';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCustomRadioEnabled } from 'state/Features/selectors';
import {
  getFavoritesImage,
  getHasMFR,
  getName,
  getSeedId,
} from 'state/Favorites/selectors';
import { getMediaServerUrl } from 'state/Config/selectors';
import { getTruncatedUsernameSelector } from 'state/Profile/selectors';
import { localize } from 'redux-i18n';

export default compose(
  localize('translate'),
  connect(
    createStructuredSelector({
      customRadioEnabled: getCustomRadioEnabled,
      displayName: getTruncatedUsernameSelector(16),
      hasMFR: getHasMFR,
      image: getFavoritesImage,
      mediaServerUrl: getMediaServerUrl,
      seedId: getSeedId,
      title: getName,
    }),
  ),
)(ProfileHero);
