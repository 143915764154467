import { ConnectedModals } from 'state/UI/constants';
import { createStation } from 'state/Stations/actions';
import { requestFavorites } from 'state/Favorites/actions';
import { STATION_TYPE } from 'constants/stationTypes';
import { useCallback, useEffect, useState } from 'react';
import type { Thunk } from 'state/types';

type Props = {
  favoritesNamed: undefined | number;
  hasMFR: boolean;
  isAnonymous: boolean;
  modalId: null | ConnectedModals;
  profileId: null | number;
  seedId: number;
  slug: string;
  urlSlugId: null | string;
};

export default function useMFRInfo({
  favoritesNamed,
  hasMFR,
  isAnonymous,
  modalId,
  profileId,
  seedId,
  slug,
  urlSlugId,
}: Props) {
  const [correctSlug, setCorrectSlug] = useState<string>();
  const [getStationInfo, setGetStationInfo] = useState<
    null | (() => Thunk<Promise<void | { id: number }>>)
  >();

  const [isActiveUser, setIsActiveUser] = useState<boolean>();
  const [needsToNameStation, setNeedsToNameStation] = useState<boolean>(false);
  const [showHowToPage, setShowHowToPage] = useState<boolean>(false);

  const getOwnStation = useCallback(
    () => () => createStation(STATION_TYPE.FAVORITES, profileId as number),
    [profileId],
  );

  const getOtherStation = useCallback(
    () => () =>
      requestFavorites(Number(urlSlugId || seedId), { bustCache: true }),
    [seedId, urlSlugId],
  );

  useEffect(() => {
    // this determines if this is user's own MFR or someone else's
    // make sure we have profile ID value before figuring this out
    if (profileId && isActiveUser === undefined) {
      // if we don't know if this is the user's own MFR
      if (isAnonymous) {
        // no personal MFR for anonymous users
        setIsActiveUser(false);
      } else if (urlSlugId || seedId) {
        // check if the slug id or seedId is the same as the profileId
        setIsActiveUser(Number(urlSlugId || seedId) === Number(profileId));
      } else {
        // else set them as active user if they are at just /favorites
        setIsActiveUser(true);
      }
    }
  }, [isActiveUser, isAnonymous, profileId, seedId, urlSlugId]);

  useEffect(() => {
    // we need a slug that matches the page requested, either
    // 1. the profile ID for a user's own MFR or
    // 2. the id from the url for someone else's station
    if (
      !correctSlug &&
      isActiveUser !== undefined &&
      slug?.includes(String(isActiveUser ? profileId : urlSlugId))
    ) {
      setCorrectSlug(slug);
    }
  }, [correctSlug, isActiveUser, profileId, slug, urlSlugId]);

  useEffect(() => {
    // this gets station information if we don't have it yet.
    if (getStationInfo === undefined && isActiveUser !== undefined) {
      if (isActiveUser && !hasMFR) {
        // user's own station and no MFR
        setGetStationInfo(getOwnStation);
      } else if (!isActiveUser && !slug?.includes(String(urlSlugId))) {
        // someone else's station and we don't have a slug that matches the url id yet
        setGetStationInfo(getOtherStation);
      } else {
        setGetStationInfo(null);
      }
    }
  }, [
    getOtherStation,
    getOwnStation,
    getStationInfo,
    hasMFR,
    isActiveUser,
    slug,
    urlSlugId,
  ]);

  useEffect(() => {
    // this determines whether to redirect to the how-to-create-MFR help page:
    // if this is a user's own MFR and we know they are not eligible yet
    // and we have tried the amp call to determine eligibility
    if (
      (isActiveUser && hasMFR === false && !!getStationInfo) ||
      (isAnonymous && !urlSlugId)
    ) {
      setShowHowToPage(true);
    }
  }, [getStationInfo, hasMFR, isActiveUser, isAnonymous, urlSlugId]);

  useEffect(() => {
    // this determines whether we need to show the name-your-MFR modal:
    // if they are eligible for MFR and this is their MFR
    // and they haven't named it and new favorites modal isn't already open
    setNeedsToNameStation(
      !!(
        hasMFR &&
        isActiveUser &&
        !favoritesNamed &&
        modalId !== ConnectedModals.NewFavorites
      ),
    );
  }, [favoritesNamed, hasMFR, isActiveUser, modalId]);

  return {
    correctSlug,
    getStationInfo,
    isActiveUser,
    needStationInfo: !!getStationInfo,
    needsToNameStation,
    showHowToPage,
  };
}
